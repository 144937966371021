import { useState, } from "react"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Formik, Form, Field } from 'formik';

import Box from '@mui/material/Box';

import { LoadingSpinner, Header,Title, ImageCollection, useInfoBlock } from "shareapps-react-ui";

function ImageScan(props) {

    let { t } = useTranslation();
    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);

    let [info, addInfo] = useInfoBlock();

    const uploadStart = () => {
        setLoading(true);
    }

    const uploadSuccess = (data) => {
        setLoading(false);
        let recipeId = data.Recipe._id;
        navigate('/recipe/' + recipeId);
    }

    const uploadError = (error) => {
        addInfo(error);
        setLoading(false);
        
    }
    return (
        <div>
            <Header back />
            {info}
            <Title title={t('RecipePictureScan')} />
            <Formik
                enableReinitialize={true}
                initialValues={{images:[]}}
                onSubmit={async (values) => {
                    console.warn("onSubmit", values);
                }}>
                <Form id="imageScanForm" className="recipes-form">
                    <Box className="shareapps-ui-vbox">
                        <Field
                            className="shareapps-ui-field-border"
                            noDirectUpload
                            uploadType="scan_images"
                            onUploadStart={uploadStart}
                            onUploadSuccess={uploadSuccess}
                            onUploadError={uploadError}
                            uploadButtonText={t("StartScan")}
                            type="images"
                            name="images"
                            label={t("ImagesToScan")}
                            component={ImageCollection} />
                    </Box>
                </Form>
            </Formik>
            <LoadingSpinner isLoading={loading} />
        </div>
    )
}

export default ImageScan;