//import { useTranslation } from "react-i18next";

function Ing(props) {

    let ingtext = props.item.ing ? props.item.ing.text : "",
        quantity = props.item.quantity ? props.item.quantity : "",
        uomtext = props.item.uom ? props.item.uom.text : "",
        quom = quantity + " " + uomtext + " " + ingtext;

    return (
        <div className="recipe-ingredients">
            {quom} 
        </div>
    )    
} 

export default Ing;