
function PrepStep(props) {

    return (
        <div className={props.className}>
            {props.prep} 
        </div>
    )    
} 

export default PrepStep;