import { HashRouter, Routes, Route, Outlet } from 'react-router-dom';

import Container from "@mui/material/Container";

import { Home } from './components/Home';
import { Error } from './components/Error';
import { Recipe } from "./components/Recipe";
import NewRecipe from "./components/NewRecipe";
import { Chapter } from "./components/Chapter";
import { Book } from "./components/Book";
import EditRecipe from "./components/EditRecipe";
import UrlScan from "./components/UrlScan";
import ImageScan from "./components/ImageScan";
import EditChapter from "./components/EditChapter";

import { Login } from 'shareapps-react-ui';
import { PwdChange } from 'shareapps-react-ui';

import './index.css';

//import reportWebVitals from './reportWebVitals';

import './App.css';

function App() {
// sx={{ background: "lightsteelblue", }}
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<div className="App">
          <Container maxWidth="lg" className="recipes" >
            <Outlet />
          </Container>
        </div>} >
          <Route index element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="pwdChange" element={<PwdChange />} />
          <Route path="book" element={<Book />}>
            <Route index element={<Book />} />
            <Route path=":bookId" element={<Book />}/>
          </Route>
          <Route path="chapter" element={<Chapter />}>
            <Route index element={<Error msg="specifyChapter" />} />
            <Route path=":chapterId" element={<Chapter />}/>
          </Route> 
          <Route path="recipe" element={<Recipe />}>
            <Route index element={<Error msg="specifyRecipe" />} />
            <Route path=":recipeId" element={<Recipe />} />
          </Route>
          <Route path="newrecipe" element={<NewRecipe />}>
            <Route index element={<Error msg="specifyRecipe" />} />
            <Route path=":recipeId" element={<NewRecipe />} />
          </Route>
          <Route path="editRecipe" element={<EditRecipe />}>
            <Route index element={<Error msg="specifyRecipe" />} />
            <Route path=":recipeId" element={<EditRecipe />} />
          </Route>
          <Route path="urlScan" element={<UrlScan />}/>
          <Route path="imageScan" element={<ImageScan />}/>
          <Route path="editChapter" element={<EditChapter />}>
            <Route index element ={<Error msg="specifyChapter"/>} />
            <Route path=":chapterId" element={<EditChapter />}/>
          </Route>
        </Route>
      </Routes>
    </HashRouter>
  );
}

export default App;