/* istanbul ignore file */
import React from 'react';
import { createRoot } from 'react-dom/client';

import { RecipeServices } from './services/recipeServices';
import { ServiceLayerProvider, ServiceLayer } from 'shareapps-react-ui';
import config from './config/config';

import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';

import i18n from './i18n';

console.log("APP VERSION: " + process.env.REACT_APP_VERSION);

var serviceLayer = new ServiceLayer();
serviceLayer.init({
  serviceUrl: config.server,
  i18n: i18n,
  config: config
}).then(() => {

  serviceLayer.addServicesSet("recipeServices", RecipeServices);

  const container = document.getElementById('root');
  const root = createRoot(container); // createRoot(container!) if you use TypeScript
  // removed react strict mode ... to make dnd work -- not really required, check 
  // shareapps TODO
  root.render(
    <ServiceLayerProvider value={serviceLayer}>
      <App />
    </ServiceLayerProvider>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  // reportWebVitals();
});
