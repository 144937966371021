

function Error(props) {

    return (

        <h2>props.msg</h2>

    );

}

export { Error }