import TextField from "@mui/material/TextField";

import { Field } from "formik";
import { useTranslation } from "react-i18next";

function EditPrepStep(props) {

    const { t } = useTranslation();

    return (
        <div className="shareapps-ui-field-wide">
            <div className="shareapps-ui-row" style={{ justifyContent: "space-between" }}>
                <Field
                    className="shareapps-ui-field shareapps-ui-field-small"
                    name={`prepSteps[${props.index}].title`}
                    label={t("recipePrepStepName")}
                    type="text"
                    as={TextField} />
            </div>
            <div>
                <Field 
                className="shareapps-ui-field"
                style={{
                    width: "90%",
                    margin: "0 0 10px 50px"}}
                name={`prepSteps[${props.index}].text`}
                label={t("recipePrepStepText")}
                multiline
                minRows="3"
                type="text"
                as={TextField}/>
            </div>
        </div>
    )
}

export default EditPrepStep;