import { useState, useEffect, useContext } from "react"
import { useNavigate, useParams } from "react-router-dom";

import Box from '@mui/material/Box';

import { useTranslation } from "react-i18next";

import { useInfoBlock, ServiceLayerContext, Header, Tile, Title } from "shareapps-react-ui";
import IngGroup from "./IngGroup";
import PrepStep from "./PrepStep";
//import AddRecipe from "./AddRecipe";
import RecipesActionPanel from "./RecipesActionPanel";
import config from '../config/config';

import "./Recipe.css";

function NewRecipe(props) {

    let { recipeId } = useParams();
    let { t } = useTranslation();
    const navigate = useNavigate();
    const serviceLayer = useContext(ServiceLayerContext);
    let [info, addInfo] = useInfoBlock();

    const onEditClick = function() {
        navigate('/editRecipe/'+ recipeId);
    }

    let [recipe, setRecipe] = useState(
            {
                title: "",
                pics:[{url: config.genericRecipeUrl}],
                intro: "",
                originURL: "",
                prep: {
                    "type": "0",
                    "order": "1",
                    "text": ""
                  },
                prepSteps:[],
                tags:[],
                ingGroups: [
                    {
                        title: "",
                        ingUsages: [{
                            quantity:0,
                            uom:{
                                text: ""
                            },
                            ing: {
                                text: ""
                            }
                        }]
                    }
                ]
            });

    useEffect(() => {
        async function getData() {
            let r = await serviceLayer.recipeServices.loadRecipe(recipeId);
            if(r.pics.length === 0) r.pics = [{url: config.genericRecipeUrl}];
            setRecipe(r);
        };
        try {
            getData();
        } catch(err) {
            addInfo(err);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipeId]);

        // build the list of ingGroups
        let ingGroupList = [];
        if(recipe.ingGroups.length > 1) {

            ingGroupList = recipe.ingGroups.map((group) => {

                return (
                    <div className="recipe-ingredients">
                        <h3>{group.title}</h3>
                        <IngGroup ings={group.ingUsages} />
                    </div>
                    );
            });
        }
        else {
            if(recipe.ingGroups.length === 1) {
                ingGroupList = <IngGroup ings={recipe.ingGroups[0].ingUsages}/>
            }
        }

        let prepSteps = [];
        if (recipe.prepSteps) {
            if (recipe.prepSteps.length > 1) {
                prepSteps = recipe.prepSteps.map((step) => {

                    return (
                        <div className="shareapps-controls recipe-ingredients">
                            <h3>{step.title}</h3>
                            <PrepStep prep={step.text} />

                        </div>
                    )
                });
            } else {
                if(recipe.prepSteps.length === 1) {
                    prepSteps = <PrepStep className="shareapps-controls" prep={recipe.prepSteps[0].text} />
                }
            }
        }

        let source = recipe.originURL ? <div className="shareapps-control shareapps-width-full"><div>{t('Source')}:</div><div><a href={recipe.originURL}>{recipe.originURL}</a></div></div>:null;
        
    return(
        <div>
        <Header back edit editAction={onEditClick} editTooltip={t('EditRecipe')}
                share shareTooltip={t('ShareRecipe')} shareLink="shareRecipe"
                print printTooltip={t('PrintRecipe')} printLink="printRecipe"/>
        {info}
        <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: "center",
            }}>
            <Tile image={recipe.pics[0]} tags={recipe.tags} className="shareapps-tile-size-full"/>
            <Title title={recipe.title} />
            <RecipesActionPanel addInfo={addInfo}/>
            <div className="shareapps-controls shareapps-width-full">
                {recipe.intro}
            </div>
            {source}
            <Box sx= {{display: "flex", justifyContent: "center", flexDirection: 'row', flexWrap:"wrap"}}>
            <div className="shareapps-controls">
            <h2 className="shareapps-headline">{t('Ingredients')}</h2>
            {ingGroupList}
            </div>
            <div>
            <h2 className="shareapps-controls shareapps-headline">{t('Prep')}</h2>
            <div>
                {prepSteps}
            </div>
            </div>
        </Box>
        </Box> 
        </div>
    );
}

export default NewRecipe;