import TextField from "@mui/material/TextField";

import { Field } from "formik";
import { useTranslation } from "react-i18next";

import EditIngs from "./EditIngs";

function EditIngGroup(props) {

    const { t } = useTranslation();
//  <div className="shareapps-ui-row"  style={{justifyContent: "space-between"}}>
    return (
        <div className="shareapps-ui-field-wide">     
            <Field
                className="shareapps-ui-field shareapps-ui-subfield shareapps-ui-field-small"
                name={`ingGroups[${props.index}].title`}
                label={t("recipeIngGroupName")}
                type="text"
                as={TextField} /> 
            <EditIngs
                {...props}
                deleteButtonTooltip="DeleteIngToolTip"
                name={`ingGroups[${props.index}].ingUsages`}
                ingGroupIndex={props.index} />
        </div>
    )
}

export default EditIngGroup;


