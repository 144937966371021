import { useEffect, useState, useContext } from 'react';
import { useField } from 'formik';

import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';

import { ServiceLayerContext } from "shareapps-react-ui";

function EditTags(props) {

    let [tags, setTags] = useState([]);
    const serviceLayer = useContext(ServiceLayerContext);
    // eslint-disable-next-line no-unused-vars
    const [field, meta, helper] = useField(props.field.name);

    const { setValue } = helper; 

    const addInfo = props.addInfo;
    // avoid warning regarding unsupported event handlers from Autocomplete ...
    let passProps = {...props};
    
    useEffect(() => {
        async function getData() {
            let t = await serviceLayer.recipeServices.loadTags();
            setTags(t);
        };
        getData().catch((err) => {
            addInfo(err);    
        });
    
    }, [serviceLayer.recipeServices, addInfo]);

    const onTagsChange = function (newValue) {
        setValue(newValue);
    }

    return (
            <Autocomplete
                {...passProps}
                disablePortal
                value={field.value}
                autoSelect
                onChange={(event, newValue) => {
                    event.stopPropagation();
                    newValue.forEach((v, i, a) => {
                        if (typeof v !== "object") {
                            a[i] = { _id: "new", text: v }
                        }
                    });
                    onTagsChange(newValue);
                }}
                id="tag-edit"
                options={tags}
                freeSolo
                multiple
                getOptionLabel={(option) => option.text}
                renderInput={(params) => <TextField {...params} label={props.label}/>}
            />
    )
} 

export default EditTags;