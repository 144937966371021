import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from 'formik';
import { useNavigate, useParams } from "react-router-dom";

import Box from "@mui/material/Box";

import { LoadingSpinner, Header, Title, ImageCollection, useInfoBlock, ServiceLayerContext } from "shareapps-react-ui";
import EditTags from "./EditTags";

function EditChapter(props) {

    let { chapterId } = useParams();
    let { t } = useTranslation();
    let [info, addInfo] = useInfoBlock();
    let [loading, setLoading] = useState(false);
    let [chapter, setChapter] = useState({
        title: "",
        tags: [],
        pic: "",
        pics:[],
        Recipes: []})
    const serviceLayer = useContext(ServiceLayerContext);
    const navigate = useNavigate();

    const handleTagsChange = () => {

    };

    useEffect(() => {
        async function getData() {
            setLoading(true);
            let c = await serviceLayer.recipeServices.loadChapter(chapterId);
            c.pics = c.pic ? [c.pic] : [];
            c.tags = [];
            setChapter(c);
            setLoading(false);
        };
        getData().catch((err) => {
            addInfo(err);
            setLoading(false);
        });
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chapterId]);

    let title = t('ChapterEdit') + chapter.title;
    // validationSchema={EditChapterSchema}
    let tags = null;
    if (serviceLayer.featureToggle("recipes-full-chapter-edit")) {
        tags = <Field
            className="shareapps-ui-field"
            name="tags"
            label={t("recipeTags")}
            onTagsChange={handleTagsChange}
            addInfo={addInfo}
            component={EditTags} />
    }
        return (
        <div>
            <Header back save formId="recipeEditForm" />
            <Title title={title} />
            {info}
            <Formik
                enableReinitialize={true}
                initialValues={chapter}             
                onSubmit={async (values) => {
                    try {
                        if (info) info = null;
                        values.pic = values.pics[0];
                        await serviceLayer.recipeServices.storeChapter(values);
                        // go back
                        navigate(-1, { replace: true });
                    } catch (err) {
                        addInfo(err);
                    }
                }}>
                <Form id="recipeEditForm" className="recipes-form">
                    <Box className="shareapps-ui-vbox shareapps-ui-vbox-left"></Box>
                    <Field
                        className="shareapps-ui-field-border"
                        name="pics"
                        label={t("ChapterImage")}
                        fileMetadata={{ chapterId: chapterId }}
                        type="images"
                        uploadType="store_images"
                        component={ImageCollection} />
                    {tags}
                </Form>
            </Formik>
            <LoadingSpinner isLoading={loading} />
        </div>
    );
}

export default EditChapter;