import { useContext, useState } from 'react';

import { Droppable, Draggable } from "react-beautiful-dnd";
import { Autocomplete, Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import DragHandleIcon from '@mui/icons-material/DragHandle';

import { useTranslation } from "react-i18next";
import { Field, useField } from "formik";

import { getItemStyle, getListStyle, ServiceLayerContext } from "shareapps-react-ui";

import './Recipe.css';

function EditIngs(props) {
    let { t } = useTranslation();

    let [ field ] = useField(props.name);
    let [rerender, setRerender] = useState(false);

    let ingGroupId="ingGroups-" + props.ingGroupIndex;

    const deleteIng= function(index) {

        field.value.splice(index, 1);
        setRerender(!rerender);

    }

    const addIng = function() {

        field.value.push({
            quantity: "",
            uom: null,
            ing: null});

        setRerender(!rerender);
    }

    const serviceLayer = useContext(ServiceLayerContext);

    const onUoMsChange = (uom, index) => {
        field.value[index].uom = uom;
        setRerender(!rerender);
    }
    const onIngChange = (ing, index) => {
        field.value[index].ing = ing;
        setRerender(!rerender);
    }

    const getLabel = (option) => {
        if(typeof option === "object") {
            return option.text;
        }
        else return option;
    }

    //console.log("RENDERING");
    return (
        <div>
            <InputLabel variant="outlined" id="innerListLabel" style={{ marginBottom: "20px", backgroundColor: "lightgreen", width: "8em", textAlign: "center" }}>
                {t("Ingredients")}
            </InputLabel> 
        <Droppable droppableId={ingGroupId} type={`ings`}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    aria-labelledby="innerListLabel"
                    style={getListStyle(snapshot.isDraggingOver)}>
                    {field.value.map((item, index) => (
                        <Draggable key={`${ingGroupId}-ing-${index}`} draggableId={`${ingGroupId}-ing-${index}`} index={index}>
                            {(provided, snapshot) => (
                                <div>
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}>
                                        <Box sx={{ flexDirection: 'row', display: 'flex', flexWrap: "wrap" }}>
                                            <span {...provided.dragHandleProps} className="shareapps-icon-width">
                                                <DragHandleIcon />
                                            </span>
                                            <Box sx={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap', justifyContent: "start"}} className="recipes-ing-width">
                                                <Field
                                                    className="shareapps-ui-field shareapps-ui-subfield recipe-quantity"
                                                    name={`ingGroups[${props.ingGroupIndex}].ingUsages[${index}].quantity`}
                                                    label={t("recipeIngQuantity")}
                                                    onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                                                    type="text"
                                                    as={TextField} />
                                                <Field
                                                    className="shareapps-ui-field shareapps-ui-subfield recipe-uom"
                                                    name={`ingGroups[${props.ingGroupIndex}].ingUsages[${index}].uom`}
                                                    label={t("recipeIngUoM")}
                                                    type="text"
                                                    disablePortal
                                                    autoSelect
                                                    onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                                                    onChange={(event, newValue) => {
                                                        event.stopPropagation();
                                                        event.preventDefault();
                                                        if (typeof newValue !== "object") {
                                                            let uom = null;
                                                            if((uom = serviceLayer.recipeServices.isUom(newValue)) !== null) {
                                                                newValue = uom;
                                                            }
                                                            else {
                                                                newValue = { _id: "new", text: newValue }
                                                            }
                                                        };
                                                        onUoMsChange(newValue, index);
                                                    }}
                                                    id="uom-edit"
                                                    options={props.uoms}
                                                    freeSolo
                                                    getOptionLabel={getLabel}
                                                    renderInput={(params) => <TextField {...params} label={props.label} />}
                                                    as={Autocomplete} />
                                                <Field 
                                                    className="shareapps-ui-field shareapps-ui-subfield recipe-ingname"
                                                    name={`ingGroups[${props.ingGroupIndex}].ingUsages[${index}].ing`}
                                                    label={t("recipeIngName")}
                                                    type="text"
                                                    disablePortal
                                                    autoSelect
                                                    onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                                                    onChange={(event, newValue) => {
                                                        event.stopPropagation();
                                                        event.preventDefault();
                                                        if (typeof newValue !== "object") {
                                                            let ing = null;
                                                            if((ing = serviceLayer.recipeServices.isIng(newValue)) !== null) {
                                                                newValue = ing;
                                                            }
                                                            else {
                                                                newValue = { _id: "new", text: newValue }
                                                            }
                                                        };
                                                        onIngChange(newValue, index);
                                                    }}
                                                    id="ing-edit"
                                                    options={props.ings}
                                                    freeSolo
                                                    getOptionLabel={getLabel}
                                                    renderInput={(params) => <TextField {...params} label={props.label} />}
                                                    as={Autocomplete} />
                                                <div>
                                                    <Tooltip title={t(props.deleteButtonTooltip?props.deleteButtonTooltip:"shareapps:deleteButtonTooltip", "Delete Item")}>
                                                        <IconButton
                                                            size="large"
                                                            color="inherit"
                                                            sx={{ mr: 2 }}
                                                            onClick={() => deleteIng(index)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </Box>
                                        </Box>
                                    </div>
                                    {provided.placeholder}
                                </div>
                            )}
                        </Draggable>
                    ))}
                    <div className="recipes-ing-width shareapps-ui-row shareapps-ui-row-right">
                        <Button sx={{ margin: "10px" }} variant="contained" endIcon={<AddIcon />} onClick={addIng}>
                            {t('AddIng')}
                        </Button>
                    </div>
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
        </div>
    )
};

export default EditIngs;