import { Book } from './Book';

// get bookId from local storage
let bookId = window.localStorage.getItem("groupId");
console.log("bookId is " + bookId)

function Home (props) {
    return (
        <Book bookId={bookId} />
    )
}

export { Home };