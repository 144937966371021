import React from "react";
import { useState, useEffect, useContext, useCallback, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom";

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { TextField } from 'formik-mui';
import Box from '@mui/material/Box';

import { useTranslation } from "react-i18next";

import { LoadingSpinner, SimpleConfirmationDialog, ServiceLayerContext, Header, useInfoBlock, ImageCollection, SortableList, AutoSave } from "shareapps-react-ui";

import EditTags from "./EditTags";
import EditPrepStep from "./EditPrepStep";

import "./Recipe.css";
import config from "../config/config";
import EditIngGroup from "./EditIngGroup";

function EditRecipe(props) {

    let { recipeId } = useParams();
    let { t } = useTranslation();
    const navigate = useNavigate(),
        serviceLayer = useContext(ServiceLayerContext);

    let [recipe, setRecipe] = useState(
        {
            title: "",
            pics: [{ url: config.genericRecipeUrl,
                    status: "externalUrl",
                    text: "generic recipe picture" }],
            intro: "",
            originURL: "",
            prepSteps: [],
            tags: [],
            ingGroups: []
        });
    let [info, addInfo] = useInfoBlock();
    let [uoms, setUoms] = useState([]);
    let [ings, setIngs] = useState([]);
    let [open, setOpen] = useState(false);
    let [loading, setLoading] = useState(false);
    let [finish, setFinish] = useState("");
    let [confirmFinish, setConfirmFinish] = useState("");

    /*
    const handleTagsChange = function(tags) {
        setRecipe((r) => ({ ...r, tags: tags}));
    }
    */

    const saveRecipe = async function(values) {
        try {
            setLoading(true);
            if(recipe.title === "") return;
            await serviceLayer.recipeServices.storeRecipe(values);
            setLoading(false);
        }catch(err) {
            addInfo(err);
            setLoading(false);
            throw err;
        }
    }

    const deleteRecipe = async function() {
        setOpen(true);
    }

    const finishDelete = function() {
        setFinish("delete");
    }

    const finishSave = function() {
        setFinish("save");
    }

    const doSaveRecipe = useCallback(function() {
        if(formRef.current) formRef.current.handleSubmit();
    }, []);

    const doDeleteRecipe = useCallback(function() {
            serviceLayer.recipeServices.deleteRecipe(recipe._id).then(() => {
                // TODO with improved navigation move to the chapter where this recipe was in ...
                navigate('/');
            }, (error) => {
                console.error("Error in deleting recipe with id "+recipe._id, error);
                addInfo(error);
            });
    }, [addInfo, navigate, serviceLayer.recipeServices, recipe._id]);

    useEffect(() => {
        async function getData() {
            let u = await serviceLayer.recipeServices.loadUoMs();
            setUoms(u);
            let i = await serviceLayer.recipeServices.loadIngredients();
            setIngs(i);
            let r = await serviceLayer.recipeServices.loadRecipe(recipeId);
            if(r.pics.length === 0) r.pics = [{url: config.genericRecipeUrl}];
            setRecipe(r);
        };
        getData().catch((err) => {
            addInfo(err);
        });
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipeId]);

    const formRef = useRef();
    useEffect(() => {
        switch(confirmFinish) {
            case "delete":
                doDeleteRecipe();
                break;
            case "save":
                doSaveRecipe();
                break;
            case "":
                break;
            default:
                console.error("undefined confirmFinish code: " + confirmFinish);
                break;
        }
    }, [confirmFinish, doDeleteRecipe, doSaveRecipe])

    // setup the validation schema
    const EditRecipeSchema = Yup.object().shape({
        title: Yup.string().required(t('RecipeTitleRequired')),
        
    });

    return (
    <div>
        <Header title={recipe.title} back delete deleteAction={deleteRecipe} backAction={finishSave} formid="recipeEditForm" />
        {info}
        <Formik
                innerRef={formRef}
                enableReinitialize={true}
                initialValues={recipe}
                validationSchema= { EditRecipeSchema }
                onSubmit={async (values) => {
                    try {
                        // TODO show a spinner ...
                        await saveRecipe(values);
                        // move to the recipe display of the saved recipe ...
                        navigate("/recipe/"+recipeId, { replace: true });
                    } catch(err) {
                        console.error("Error while saving recipe at submit", err);
                    }                        
                }}>
                <Form id="recipeEditForm" className="recipes-form">
                    <Box className="shareapps-ui-vbox shareapps-ui-vbox-left">
                        <Field
                            className="shareapps-ui-field"
                            required
                            name="title"
                            label={t("recipeTitle")}
                            type="text"
                            component={TextField}/>
                        <Field
                            className="shareapps-ui-field-border"
                            name="pics"
                            label={t("recipeImages")}
                            fileMetadata={{recipeId: recipeId}}
                            uploadType="store_images"
                            component={ImageCollection}/>
                        <Field
                            className="shareapps-ui-field"
                            name="tags"
                            label={t("recipeTags")}
                            addInfo={addInfo}
                            component={EditTags}/>
                        <Field
                            className="shareapps-ui-field"
                            name="intro"
                            label={t("recipeIntro")}
                            multiline
                            minRows="3"
                            type="text"
                            component={TextField}/>
                        <SortableList
                            subListProps={{uoms: uoms, ings:ings}}
                            className="shareapps-ui-field-border"
                            listType="ingGroup"
                            subListType="ings"
                            subListPath="ingUsages"
                            name="ingGroups"
                            label={t("recipeIngGroups")}
                            labelid="ingGroupsLabelId"
                            listIdPrefix="ingGroup"
                            listComponent={EditIngGroup}
                            deleteButtonTooltip="DeleteIngGroupTooltip"
                            addItemProps={{
                                prototype: {
                                    title: "",
                                    ingUsages: [{
                                        quantity: "",
                                        uom: {_id: "", text: ""},
                                        ing: {_id: "", text: ""}}]
                                    },
                                buttonText: t('AddIngGroup'),
                                buttonToolTip: t('AddIngGroupTooltip')
                            }}
                            />
                        <SortableList 
                            className="shareapps-ui-field-border"
                            listType="prepStep"
                            name="prepSteps"
                            label={t("recipePrepSteps")}
                            labelid="prepStepsLabelId"
                            listIdPrefix="prepStep"
                            listComponent={EditPrepStep}
                            deleteButtonTooltip="DeletePrepStepTooltip"
                            addItemProps={{
                                prototype: {
                                    title: "",
                                    text: ""
                                },
                                buttonText: t('AddPrepStep'),
                                buttonToolTip: t('AddPrepStepTooltip')
                            }}/>
                    </Box>
                    <AutoSave saveFn={saveRecipe} finish={finish} setConfirmFinish={setConfirmFinish}/>
                </Form>
            </Formik>
            <SimpleConfirmationDialog 
                title={t('RecipeDeleteConfirmTitle')}
                open={open}
                setOpen={setOpen}
                children={t('RecipeDeleteConfirmText')}
                onConfirm={finishDelete}          
            />
            <LoadingSpinner isLoading={loading} />
    </div>
        )
};

export default EditRecipe