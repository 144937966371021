import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { ServiceLayerContext, LoadingSpinner, useInfoBlock, Header, TileList } from 'shareapps-react-ui';
import AddRecipe from "./AddRecipe";
import config from '../config/config';


function Book(props) {

    var { bookId } = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();
    // if no bookId is given as parameter, get it from localStorage
    if(!bookId) bookId = localStorage.getItem("groupId");
    let [book, setBook] = useState({title: "", Chapters:[]});
    let [loading, setLoading] = useState(false);
    let [info, addInfo] = useInfoBlock();
    const serviceLayer = useContext(ServiceLayerContext);

    useEffect(() => {
        async function getData() {
            try {
                // temporary workaroud until we have cache implemented ...
                setLoading(true);
                let b = await serviceLayer.recipeServices.loadBook(bookId);
                setLoading(false);
                setBook(b);
            }
            catch (err) {
                addInfo(err);
                setLoading(false);
            }
        };

        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookId]);

    // <Title title={book.title} />

    return (
        <div>
            <Header menu user search title={book.title}
                searchPath="chapter" searchTooltip={t('RecipeSearch')}
                add addPopoverContent={<AddRecipe addInfo={addInfo}/>}
                addTooltip={t('AddRecipesAndChapters')}/>
            {info}
            <TileList
                tiles={book.Chapters}
                defaultPicUrl={config.genericRecipeUrl}
                prefix="chapter"
                editButton
                editButtonTooltipText={t('ChapterEditButtonTooltip')}
                editButtonAction={(id) => {
                    navigate('/editChapter/'+id)
                }} />
            <LoadingSpinner isLoading={loading} />
        </div>
    );
}

export { Book };