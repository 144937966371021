import { ServicesBase } from "shareapps-react-ui";

class RecipeServices extends ServicesBase {

    constructor(serverurl, options) {
       super (serverurl, options)

       this.recipeSet = null;
       this._uoms = [];
       this._ingredients = [];
    }
    
    getCurrentRecipeSet() {
        return this.recipeSet;
    }

    setCurrentRecipeSet(set) {

        this.recipeSet = set;

    }

    async loadBook(bookId, options={}) {
        return await this.get("book", bookId, options);
    }

    async loadTags(options={}) {

        let tags = await this.get("tags", "all", options);
        return tags;

    }

    async loadUoMs(options={}) {
        this._uoms = await this.get("uoms", "all", options);
        return this._uoms;
    }

    isUom(uom) {
        let uomObj = this._uoms.find((u) => {
            return (u.text === uom);
        })
        if (typeof uomObj === "undefined") uomObj = null;
        return uomObj;
    }

    async loadIngredients(options={}) {
        this._ingredients = await this.get("ingredients", "all", options);
        return this._ingredients;
    }

    isIng(word) {
        let ingObj = this._ingredients.find((i) => {
            return (i.text === word);
        })
        if(typeof ingObj === "undefined") ingObj = null;
        return ingObj;
    }

    async loadChapter(param, options={}) {

        var chapter;
        param = decodeURI(param);
        if(param[0] === 'S') {
            // param is a query ...
            let queryStr = param.substr(1);
            // prepare query
            var query = {};
            var cmd = queryStr.split(":");
            queryStr = cmd[1];
            query.type = cmd[0];
            query.queryStr = queryStr;

            let recipes = await this.query("recipes", query, options);
            chapter = {
                titleTransTag: "shareapps:SearchResults",
                Recipes: recipes
            }
        } else {
            // query for a chapterId, in this case param is the chapterId
            chapter = await this.get("chapter", param, options);
        }
        
        return chapter;
    }

    async storeChapter(chapter) {
        try {
            var result = this.set("chapter", chapter._id, chapter);
            return result;

        } catch(err) {
            throw err;
        }
    }

    async loadRecipe(recipeId) {
        return await this.get("recipe", recipeId);
    }

    async storeRecipe(recipe) {

        try {
            var context = recipe._id;
            delete recipe.context;
            
            var result = await this.set("recipe", recipe._id, recipe, {noCache: true});
            console.log("result from store_recipe API call: ", result);
            // returns the recipe id of the stored recipe ...
            return await this._handle_stored_recipe(result, context);	
        }
        catch(error) {
            throw error;
        }
    }

    async deleteRecipe(recipeId) {
        try {
            await this.delete("recipe", recipeId);
        } catch(error) {
            throw error;
        }
    }

    async _handle_stored_recipe(result, context) {

        // the server will return the fully verified (and equiped with the right 
        // ingredients, tag, etc. ids back for storage in the recipe> model ...

        // add new tags


        var tags = await this.get("tags", "all");
        if (!Array.isArray(tags)) {
            tags = [];
        }
        tags = tags.concat(result.Newtags);
        this.set("tags", "all", tags, {cacheOnly: true});

        // add new ingredients
        var ings = await this.get("ingredients", "all");
        if (!Array.isArray(ings)) {
            ings = [];
        }
        ings = ings.concat(result.Newings);
        this.set("ingredients", "all", ings, {cacheOnly: true});

        // add new uom
        var uoms = await this.get("uoms", "all");
        if (!Array.isArray(uoms)) {
            uoms = [];
        }
        uoms = uoms.concat(result.Newuoms);
        this.set("uoms", "all", uoms, {cacheOnly: true});

        // update current recipe model
        this.set("recipe", result.Recipe._id, result.Recipe, {cacheOnly: true});
        let recipe = result.Recipe;

        // update recipe header in chapter model ...	
        // TODO use cache instead of getCurretRecipeSet model, or ...
        // whhat if, the current set is a search result?			
        let recipes = this.getCurrentRecipeSet();
        // if there is no current recipe set now, return here ...
        if(recipes === null) return recipe._id;
        recipes = recipes.Recipes;
       
        let header = {
            // copy only the header items
            _id: recipe._id,
            pics: recipe.pics,
            tags: recipe.tags,
            bookId: recipe.bookId,
            title: recipe.title,
            prepTime: recipe.prepTime,
            userId: recipe.userId
        };

        if (typeof context == "undefined") {
            // new recipe ... add to array at the beginning of array
            recipes.unshift(header);
        }
        else {
            // update existing recipe
            // need to find the index of the recipe with id == context in array					
            var recipeIndex = recipes.findIndex(recipe => recipe._id === context);
            // error handling
            if (recipeIndex === -1) {
                // resilience, take as new one
                recipes.unshift(header);
            }
            else {
                recipes[recipeIndex] = header;
            }
        }
        // return the recipe id for the stored recipe (especially for new recipe)
        return recipe._id;
    }

    async scanRecipeUrl(url) {
        
        try {
            let result = await this.api('scan_url', {url: url}, {timeout: 10000});
            return await this._handle_stored_recipe(result);
        } catch(error) {
            throw error;
        }
    }

    /*
    async scanRecipeImages(images) {
        
        try {
            let result = await this.api('scan_', {url: url});
            return await this._handle_stored_recipe(result);
        } catch(error) {
            throw error;
        }
    }
    */
}

export { RecipeServices }

