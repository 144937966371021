import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom";

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { TextField} from 'formik-mui';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";

import { useTranslation } from "react-i18next";

import { LoadingSpinner, ServiceLayerContext, Header, useInfoBlock, Title, isValidUrl } from "shareapps-react-ui";

function UrlScan(props) {

    let { t } = useTranslation();
    const navigate = useNavigate();
    const serviceLayer = useContext(ServiceLayerContext);

    const UrlSchema = Yup.object().shape({
        url: Yup.string().test('is-url-valid', t("shareapps:InvalidUrl"), (value) => isValidUrl(value)).required(t('URLRequired')),
    });

    let [info, addInfo] = useInfoBlock();
    let [loading, setLoading] = useState(false);

    return (
        <div>
            <Header back />
            {info}
            <Title title={t('RecipeUrlScan')} />
            <Formik
                enableReinitialize={true}
                initialValues={{url:""}}
                validationSchema={UrlSchema}
                onSubmit={async (values) => {
                    try {
                        if (info) info = null;
                        setLoading(true);
                        let recipeId = await serviceLayer.recipeServices.scanRecipeUrl(values.url);
                        // go back
                        setLoading(false);
                        navigate("/recipe/" + recipeId);
                    } catch (err) {
                        addInfo(err);
                        setLoading(false);
                    }
                }}>
                <Form id="urlScanForm" className="recipes-form">
                    <Box className="shareapps-ui-vbox">
                        <Field
                            className="shareapps-ui-field"
                            required
                            name="url"
                            label={t("UrlToScan")}
                            type="text"
                            component={TextField} />
                        <Button
                            type="submit"
                            variant="contained">
                            {t("UrlScan")}
                        </Button>
                    </Box>
                </Form>
            </Formik>
            <LoadingSpinner isLoading={loading} />
        </div>
    )

}

export default UrlScan;