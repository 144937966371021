import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {  LoadingSpinner, ServiceLayerContext, useInfoBlock, Header, TileList } from 'shareapps-react-ui';
import AddRecipe from './AddRecipe';
import config from '../config/config';

function Chapter(props) {

    let { t } = useTranslation();
    let { chapterId } = useParams();
    let [chapter, setChapter] = useState({title: "", Recipes: []});
    var [info, addInfo] = useInfoBlock();
    let [loading, setLoading] = useState(false);
    const serviceLayer = useContext(ServiceLayerContext);

    useEffect(() => {
        async function getData() {
            try {
                setLoading(true);
                let c = await serviceLayer.recipeServices.loadChapter(chapterId);
                if(!c.title) {
                    let queryStr = chapterId.split(':').pop();
                    queryStr = queryStr.replace("_", " ");
                    c.title = t(c.titleTransTag, "Search Results") + " " + queryStr;
                }
                setChapter(c);
                setLoading(false);
            }
            catch (err) {
                addInfo(err);
                setLoading(false);
            }
        };

        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chapterId]);

    let recipelink = "recipe";
    if(serviceLayer.featureToggle('recipes-new-recipe-layout')) {
        recipelink = "newrecipe";
    }

    return (
        <div>
            <Header back user search searchPath="chapter" searchTooltip={t('RecipeSearch')}
                title={chapter.title}
                add addPopoverContent={<AddRecipe addInfo={addInfo}/>}
                addTooltip={t('AddRecipesAndChapters')}/>
            {info}
            <TileList
                tiles={chapter.Recipes}
                defaultPicUrl={config.genericRecipeUrl}
                prefix={recipelink} />
            <LoadingSpinner isLoading={loading} />
        </div>
    );
}

export{ Chapter };