import Ing from './Ing';


function IngGroup(props) {

    const ings = props.ings.map((item) => {
        return <Ing item={item} />
    });
       
    return <div className="recipe-ingredients">{ings}</div>;
       
} 

export default IngGroup;