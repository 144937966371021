import { useTranslation } from "react-i18next";

import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

function RecipesActionPanel(props) {

    const { t } = useTranslation();

    const addToShoppingList = () => {props.addInfo({type: "warning", text: t('shareapps:NotYetAvailable')})};

    const putOnWeekPlan = () => {props.addInfo({type: "warning", text: t('shareapps:NotYetAvailable')})};
    return (
        <Toolbar className="recipes-action-panel">
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Tooltip title={t('PutOnShoppingList')}>
                    <IconButton
                        size="large"
                        edge="end"
                        color="inherit"
                        aria-label="add to shopping list"
                        sx={{ mr: 2 }}
                        onClick={addToShoppingList}
                        className="shareapps-header-button-right">
                        <ShoppingBasketIcon fontSize="large"/>
                    </IconButton>
            </Tooltip>
        </Box>
        <Tooltip title={t('CookRecipeTooltip')}>
                    <Button
                        size="large"
                        edge="end"
                        color="inherit"
                        variant="contained"
                        aria-label="cook"
                        sx={{ mr: 2 }}
                        onClick={addToShoppingList}
                        className="shareapps-header-button-right">
                    {t('CookRecipe')}
                    </Button>
            </Tooltip>
            <Tooltip title={t('PutOnWeekPlan')}>
                    <IconButton
                        size="large"
                        edge="end"
                        color="inherit"
                        aria-label="put on Week Plan"
                        sx={{ mr: 2 }}
                        onClick={putOnWeekPlan}
                        className="shareapps-header-button-right">
                        <CalendarMonthIcon fontSize="large"/>
                    </IconButton>
            </Tooltip>
    </Toolbar> 
    )
}

export default RecipesActionPanel;